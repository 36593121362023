<template>
  <router-view/>
</template>

<script setup>
  // import api from './utils/api.js';
  // import Banma from './utils/Banma.js';
 
  const baseSize = 37.5
  // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 375
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 1) + 'px'
  }
  // 初始化
  setRem()
  // 改变窗口大小时重新设置 rem
  window.onresize = function() {
    setRem()
  }
</script>

<style lang="less">
*{
  -webkit-touch-callout:none;/*系统默认菜单被禁用*/
  -webkit-user-select:none;/*webkit浏览器*/
  -khtml-user-select:none;/*早起浏览器*/
  -moz-user-select:none;/*火狐浏览器*/
  -ms-user-select:none;/*IE浏览器*/
  user-select:none;/*用户是否能够选中文本*/
}
body {
  margin: 0;
}
#app {
  // text-align: center;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
}

p{
  margin-block-start: 0em;
  margin-block-end: 0em;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
