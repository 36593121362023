import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/axios-config';
import api from './utils/api';

if(api.getTime()){
  var date = new Date();
  let tm = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  if(api.getTime() != tm){
    localStorage.removeItem('access_token');
  }
}else{
  localStorage.removeItem('access_token');
}

const app = createApp(App)

app.use(store).use(router).mount('#app');