var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

//注册事件监听
function connectWebViewJavascriptBridge(callback) {

	if (isAndroid) {
		if (window.WebViewJavascriptBridge) {
			callback(WebViewJavascriptBridge);
		} else {
			document.addEventListener("WebViewJavascriptBridgeReady",function() {
				callback(WebViewJavascriptBridge)
			}, false);
		}
	}

	if (isiOS) {
		if (window.WebViewJavascriptBridge) {
			return callback(WebViewJavascriptBridge);
		}
		if (window.WVJBCallbacks) {
		 console.log("window.WVJBCallbacks", window.WVJBCallbacks.push(callback))
		 return window.WVJBCallbacks.push(callback);
		}
		window.WVJBCallbacks = [callback];
		var WVJBIframe = document.createElement('iframe');
		WVJBIframe.style.display = 'none';
		WVJBIframe.src = 'https://__bridge_loaded__';
		document.documentElement.appendChild(WVJBIframe);
		setTimeout(function() {
			document.documentElement.removeChild(WVJBIframe)
		}, 0)
	}
}

connectWebViewJavascriptBridge(function(bridge) {
	if (isAndroid) {
		//注册回调函数，接收Android端用send方法发送的消息
		bridge.init(function(message, responseCallback) {
			var responseData = "Received!";
			responseCallback(responseData);
		});
	}
})

var Banma = {}

//获取顶部状态栏高度
Banma.getStatusBarHeight = (cb) => {
	if (isAndroid) {
		//调用Android端注册的getInformation方法进行数据查询
		window.WebViewJavascriptBridge.callHandler("getStatusBarHeight", '', function(ret) {
			//console.log('ret00',ret)
			cb(ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			console.log("调用getStatusBarHeight方法")
			bridge.callHandler("getStatusBarHeight", '', (ret) => {
				cb(ret);
			});
		})
	}

}

//获取用户信息
Banma.getUserInfo = (cb) => {

	if (isAndroid) {
		//调用Android端注册的getUserInfo方法进行数据查询
		window.WebViewJavascriptBridge && window.WebViewJavascriptBridge.callHandler("getUserInfo", '', function(ret) {
			console.log('isiOS-getUserInfo', ret);
			cb(ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			console.log("调用getUserInfo方法");
			bridge.callHandler("getUserInfo", '', (ret) => {
				console.log("getUserInfo返回", ret);
				cb(ret);
			});
		})
	}

}

//返回
Banma.onBack = () => {
	if (isAndroid) {
		window.WebViewJavascriptBridge.callHandler("onBack", '', function(ret) {
			console.log('ret', ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			bridge.callHandler("onBack", '', (ret) => {});
		})
	}

}

//去登陆
Banma.toLogin = () => {
	if (isAndroid) {
		window.WebViewJavascriptBridge.callHandler("toLogin", '', function(ret) {
			console.log('ret', ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			bridge.callHandler("toLogin", '', (ret) => {});
		})
	}

}

//分享主页
Banma.agentCenterToShare = (params) => {
	console.log('agentCenterToShare', params)
	if (isAndroid) {
		window.WebViewJavascriptBridge.callHandler("agentCenterToShare", JSON.stringify(params), function(ret) {
			console.log('ret', ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			bridge.callHandler("agentCenterToShare", JSON.stringify(params), (ret) => {});
		})
	}

}

//打电话
Banma.callPhone = (params) => {
	console.log('callPhone')
	if (isAndroid) {
		window.WebViewJavascriptBridge.callHandler("callPhone", JSON.stringify(params), function(ret) {
			console.log('ret', ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			bridge.callHandler("callPhone", JSON.stringify(params), (ret) => {});
		})
	}
}

//职位分享
Banma.jobShare = (params) => {
	console.log('toShare', params)
	if (isAndroid) {
		window.WebViewJavascriptBridge.callHandler("toShare", JSON.stringify(params), function(ret) {
			console.log('ret', ret);
		});
	}

	if (isiOS) {
		connectWebViewJavascriptBridge(function(bridge) {
			bridge.callHandler("toShare", JSON.stringify(params), (ret) => {});
		})
	}

}

// 代报名渠道
Banma.replaceSingUpFrom = () => {
	let fromInfo = {}
	if (isAndroid) {
		fromInfo = {
			fromKey : "f463fc65f18811e594980800277a9591",
			appChannelCode : "DBzTbLrFXwaePYisD4uDfay6HldVsvfW",
			appSourceCode : "ylife_self_recruit",
			behavior : "job_posting"
		}
	}
	if (isiOS) {
		fromInfo = {
			fromKey : "fdc531e3f18811e594980800277a9591",
			appChannelCode : "DBzTbLrFXwaePYisD4uDfay6HldVsvfW",
			appSourceCode : "ylife_self_recruit",
			behavior : "job_posting"
		}
	}
	return fromInfo
}

export default Banma;
