import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/PositionDetails',// 职位详情
    name: 'PositionDetails',
    component: () => import('../views/PositionDetails.vue')
  },
  {
    path: '/PrincipalSheet',//主页
    name: 'PrincipalSheet',
    component: () => import('../views/PrincipalSheet.vue')
  },
  {
    path: '/PositionCollect',//职位收藏
    name: 'PositionCollect',
    component: () => import('../views/PositionCollect.vue')
  },
  {
    path: '/CommissionOrder',//佣金订单
    name: 'CommissionOrder',
    component: () => import('../views/CommissionOrder.vue')
  },
  {
    path: '/ReplaceSingUp',//代报名
    name: 'ReplaceSingUp',
    component: () => import('../views/ReplaceSingUp.vue')
  },
  {
    path: '/Delivery',//他的投递
    name: 'Delivery',
    component: () => import('../views/Delivery.vue')
  },
  {
    path: '/commission',//佣金配置
    name: 'commission',
    component: () => import('../views/commission.vue')
  },
  {
    path: '/IncomeDetails',//收入明细
    name: 'IncomeDetails',
    component: () => import('../views/IncomeDetails.vue')
  },
  {
    path: '/RecruitOrder',//招聘订单
    name: 'RecruitOrder',
    component: () => import('../views/RecruitOrder.vue')
  },
  {
    path: '/GetCommission',//代领佣金
    name: 'GetCommission',
    component: () => import('../views/GetCommission.vue')
  },
  {
    path: '/ResumeDetail',//简历详情
    name: 'ResumeDetail',
    component: () => import('../views/ResumeDetail.vue')
  },
  {
    path: '/ChangePosition',//更换职位
    name: 'ChangePosition',
    component: () => import('../views/ChangePosition.vue')
  },
  {
    path: '/StraightTalk',//直聊
    name: 'StraightTalk',
    component: () => import('../views/StraightTalk.vue')
  },
  {
    path: '/System',//服务消息
    name: 'System',
    component: () => import('../views/System.vue')
  },
  {
    path: '/UserAgreement',//用户协议
    name: 'UserAgreement',
    component: () => import('../views/UserAgreement.vue')
  },
  {
    path: '/PrivacyPolicy',//隐私政策
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/LogOff',//隐私政策
    name: 'LogOff',
    component: () => import('../views/LogOff.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
