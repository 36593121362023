import { createStore } from 'vuex'
import api from '@/utils/api.js';

export default createStore({
  state: {
    // 当前用户信息
	// user: api.takeUser(),
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
