
export default {
	heightName:'BarHeight',
	getHeight(){
		let height = localStorage.getItem(this.heightName);
		if (!height) {
		  height = sessionStorage.getItem(this.heightName);
		}
		return height;
	},
	cacheHeight(height) {
	  localStorage.removeItem(this.tokenStoreName);
	  if (height) {
	    localStorage.setItem(this.heightName,height);
	  }
	},
	timeName:'cathTime',
	getTime(){
		let t = localStorage.getItem(this.timeName);
		return t;
	},
	cacheTime(time) {
	  localStorage.removeItem(this.timeName);
	  if (time) {
	    localStorage.setItem(this.timeName,time);
	  }
	},
	// token传递的header名称
	tokenHeaderName: 'Authorization',
	// token存储的名称
	tokenStoreName: 'access_token',
	// 缓存token
	cacheToken(token, remember) {
		localStorage.removeItem(this.tokenStoreName);
		if (token) {
		  localStorage.setItem(this.tokenStoreName, token);
		}
	  },
	// 获取缓存的token
	takeToken() {
	  let token = localStorage.getItem(this.tokenStoreName);
	  if (!token) {
	    token = localStorage.getItem(this.tokenStoreName);
	  }
	  return token;
	},
	// 用户信息存储的名称
	userStoreName: 'user',
	// 获取缓存的用户信息
	takeUser() {
	  try {
	    return JSON.parse(localStorage.getItem(this.userStoreName)) || {};
	  } catch (e) {
	    console.error(e);
	  }
	  return {};
	},
	// 缓存用户信息
	cacheUser(user) {
	  if (user) {
	    localStorage.setItem(this.userStoreName, JSON.stringify(user));
	  } else {
	    localStorage.removeItem(this.userStoreName);
	  }
	}
}