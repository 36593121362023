/**
 * axios配置
 */
import axios from 'axios';
import store from '../store';
import router from '../router';
import api from './api';
import Banma from './Banma';
import { Toast } from 'vant';
import qs from 'qs';
// 设置统一的url

//console.log('axios.defaults',axios.defaults)
axios.defaults.baseURL = '/api';

/* 请求拦截器 */
axios.interceptors.request.use((config) => {
  // 添加token到header

  let token = api.takeToken();
  // let token = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOjE0NjYyNTQwOTk5NTc2ODYyNzQsImFjY291bnQiOiIxMDgzODciLCJ1dWlkIjoiZGM0ZjgwYzQtNDE0Yy00NGRiLWFmYjctZjE2ZTExNWRiMzFhIiwicmVtZW1iZXJNZSI6ZmFsc2UsImV4cGlyYXRpb25EYXRlIjoxNjUwMDc1MTM5MTMxLCJjYVRva2VuIjpudWxsLCJvdGhlcnMiOm51bGwsInN1YiI6IjE0NjYyNTQwOTk5NTc2ODYyNzQiLCJpYXQiOjE2NDk0NzAzMzksImV4cCI6MTY1MDA3NTEzOX0.HziDYL3-JoxhHZ_FeruH9szaCLDd4P0dnwhGcD5G8c1lzqKjuUcZYDdz2CkDkfujeLU8kQiTMwhnTzwXVbu9Og';
  // console.log('token',token);
  if (token) {
    config.headers[api.tokenHeaderName] = token;
    config.headers['CORP-KEY'] = 'b72a5c603bf97128613849c02f33ffd1';
    // 如果是get请求，就把参数拼接到url上
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'indices' });
      }
    }
  }
  // console.log('请求头', config.headers)
  return config;
}, function (error) {
  return Promise.reject(error)
});

/* 响应拦截器 */
axios.interceptors.response.use((res) => {
  console.log('interceptors',res)
  // 请求成功，token自动续期
  if (res.data.code === '00000') {
    let access_token = res.headers[api.tokenHeaderName];
    if (access_token) {
      api.cacheToken(access_token);
    }
    return res;
  }
  if(res.status == '200' && res.request.responseType == 'blob'){
    let access_token = res.headers[api.tokenHeaderName];
    if (access_token) {
      api.cacheToken(access_token);
    }
    return res;
  }
  if (res.status == '200' && res.request.responseText) {
    let access_token = res.headers[api.tokenHeaderName];
    if (access_token) {
      api.cacheToken(access_token);
    }
    return res;
  }

  // 处理响应错误，请求异常自动提示错误信息，如果是B0301就跳转到登录界面
  return processErrorResponse(res);
}, (error) => {
  // 处理响应错误
  return processErrorResponse(error.response);
});

/**
 * 跳转到登录页面
 */
function goLogin(reload) {
  store.dispatch('user/removeToken').then(() => {
    if (reload) {
      location.replace('/');  // 这样跳转避免再次登录重复注册动态路由
    } else {
      const path = router.currentRoute.path;
      router.push({
        path: '/',
        query: path && path !== '/' ? {form: path} : null
      });
    }
  });
}

/**
 * 处理错误的响应
 *
 * @author fengshuonan
 * @date 2021/4/2 15:33
 */
function processErrorResponse(response) {
  // console.log('response',response)
  // 如果是非B0301，则提示错误信息，如果是B0301，则跳转登录界面
  if (response.data.code !== 'B0301') {
    Toast(response.data.message?response.data.message:'系统错误,请稍后再试。');
  } else {
	  
	//console.log('登录状态已过期, 请退出重新登录!',response.config)；
	localStorage.removeItem('access_token');
	Toast('登录状态已过期, 请退出重新进入!');
	// localStorage.clear();
	// Banma.toLogin();
    // if (response.config.url === api.menuUrl) {
    //   goLogin();
    // } else {
    //   Modal.destroyAll();
    //   Modal.info({
    //     title: '系统提示',
    //     content: '登录状态已过期, 请退出重新登录!',
    //     okText: '重新登录',
    //     onOk: () => {
    //       goLogin(true);
    //     }
    //   });
    // }
  }
  return Promise.reject(new Error(response.data));
}
